import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    { attrs: { flat: "" } },
    [
      _c(
        "div",
        { staticStyle: { padding: "10px" } },
        [
          _c(
            VAlert,
            {
              staticStyle: { "text-align": "left" },
              attrs: { dense: "", color: "blue", outlined: "", type: "info" },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.progressMessage) +
                  "\n            "
              ),
              _vm.progressMessageOutput && _vm.progressMessageOutput.length > 0
                ? _c(
                    VBtn,
                    {
                      staticStyle: { color: "#2196F3" },
                      attrs: { icon: "", "x-small": "" },
                      on: {
                        click: function ($event) {
                          _vm.queryDialogTitle = "Generator Output"
                          _vm.queryDialogContent = _vm.progressMessageOutput
                          _vm.isQueryDialogOpen = true
                        },
                      },
                    },
                    [_c(VIcon, [_vm._v("mdi-magnify")])],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            height: "55vh",
            overflow: "auto",
            padding: "10px",
            "border-bottom": "solid 2px rgba(0, 0, 0, 0.2)",
          },
          attrs: { id: "scroll_messageList" },
        },
        [
          _c(
            VCol,
            { staticClass: "pa-0", attrs: { cols: "12" } },
            _vm._l(_vm.responseQueries, function (reponseQuery, index) {
              return _c(
                "div",
                { key: index },
                [
                  _c(
                    VSheet,
                    {
                      staticClass: "grey lighten-3 pa-2",
                      staticStyle: {
                        display: "inline-block",
                        width: "415px",
                        "text-align": "left",
                        "margin-bottom": "10px",
                        "border-radius": "6px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            height: "25px",
                            display: "flex",
                            "justify-content": "space-between",
                            "align-items": "center",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c(
                                "pre",
                                {
                                  staticStyle: {
                                    "font-size": "small",
                                    "text-align": "left",
                                    "font-weight": "bold",
                                  },
                                },
                                [_vm._v("#" + _vm._s(reponseQuery.hash))]
                              ),
                              _c(
                                VBtn,
                                {
                                  staticStyle: { "margin-left": "1px" },
                                  attrs: { icon: "", "x-small": "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.logDialogTitle =
                                        "Debezium Log #" + reponseQuery.hash
                                      _vm.logDialogContent =
                                        reponseQuery.rawTransaction
                                      _vm.isLogDialogOpen = true
                                    },
                                  },
                                },
                                [_c(VIcon, [_vm._v("mdi-text")])],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("div", { staticStyle: { height: "15px" } }, [
                        _c(
                          "pre",
                          {
                            staticStyle: {
                              "font-size": "small",
                              "text-align": "left",
                            },
                          },
                          [_vm._v("USECASE: " + _vm._s(reponseQuery.usecase))]
                        ),
                      ]),
                      _c("div", { staticStyle: { height: "20px" } }, [
                        _c(
                          "pre",
                          {
                            staticStyle: {
                              "font-size": "small",
                              "text-align": "left",
                            },
                          },
                          [_vm._v("ACTOR: " + _vm._s(reponseQuery.actor))]
                        ),
                      ]),
                      !reponseQuery.errorMessage ||
                      reponseQuery.errorMessage.length === 0
                        ? _c(
                            "div",
                            [
                              _c("div", { staticStyle: { height: "18px" } }, [
                                _c(
                                  "pre",
                                  {
                                    staticStyle: {
                                      "font-size": "small",
                                      "text-align": "left",
                                    },
                                  },
                                  [_vm._v("EVENT STORMING UPDATES")]
                                ),
                              ]),
                              _vm._l(
                                reponseQuery.queries,
                                function (query, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticStyle: { display: "flex" },
                                    },
                                    [
                                      _c(
                                        "pre",
                                        {
                                          staticStyle: {
                                            "font-size": "small",
                                            "text-align": "left",
                                            "white-space": "normal",
                                            "word-wrap": "break-word",
                                            "overflow-wrap": "break-word",
                                            "max-width": "380px",
                                          },
                                        },
                                        [_vm._v("- " + _vm._s(query.summary))]
                                      ),
                                      _c(
                                        VBtn,
                                        {
                                          staticStyle: { "margin-top": "2px" },
                                          attrs: { icon: "", "x-small": "" },
                                          on: {
                                            click: function ($event) {
                                              _vm.queryDialogTitle =
                                                "Event Storming Update: " +
                                                query.summary
                                              _vm.queryDialogContent =
                                                query.rawQuery
                                              _vm.isQueryDialogOpen = true
                                            },
                                          },
                                        },
                                        [_c(VIcon, [_vm._v("mdi-magnify")])],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        : _c("div", [
                            _c(
                              "pre",
                              {
                                staticStyle: {
                                  "font-size": "small",
                                  "text-align": "left",
                                },
                              },
                              [
                                _vm._v(
                                  "AI 생성 결과를 처리하는 도중에 에러가 발생했습니다."
                                ),
                              ]
                            ),
                            _c(
                              "pre",
                              {
                                staticStyle: {
                                  "font-size": "small",
                                  "text-align": "left",
                                },
                              },
                              [_vm._v("다시 시도해 주시길 바랍니다.")]
                            ),
                            _c(
                              "pre",
                              {
                                staticStyle: {
                                  "font-size": "small",
                                  "text-align": "left",
                                },
                              },
                              [_vm._v("* 에러 메세지")]
                            ),
                            _c(
                              "pre",
                              {
                                staticStyle: {
                                  "font-size": "small",
                                  "text-align": "left",
                                  "white-space": "normal",
                                  "word-wrap": "break-word",
                                  "overflow-wrap": "break-word",
                                  "max-width": "380px",
                                },
                              },
                              [_vm._v(_vm._s(reponseQuery.errorMessage))]
                            ),
                          ]),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
      _c(VTextField, {
        staticClass: "prompt_field generator-ui-text-field",
        attrs: {
          solo: "",
          autofocus: "",
          "append-icon": "mdi-send",
          disabled: !_vm.isGenerationFinished,
        },
        on: {
          "click:append": function ($event) {
            return _vm.generate()
          },
          keypress: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.generate()
          },
        },
        model: {
          value: _vm.debeziumLogs,
          callback: function ($$v) {
            _vm.debeziumLogs = $$v
          },
          expression: "debeziumLogs",
        },
      }),
      _c(
        VDialog,
        {
          attrs: { "max-width": "1000", "max-height": "1000" },
          model: {
            value: _vm.isLogDialogOpen,
            callback: function ($$v) {
              _vm.isLogDialogOpen = $$v
            },
            expression: "isLogDialogOpen",
          },
        },
        [
          _c(
            VCard,
            [
              _c(
                "v-card-item",
                [
                  _c(
                    VCardTitle,
                    { staticStyle: { display: "flex" } },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.logDialogTitle) +
                          "\n                    "
                      ),
                      _c(
                        VBtn,
                        {
                          staticStyle: {
                            "margin-top": "2px",
                            "margin-left": "2px",
                          },
                          attrs: { icon: "", small: "" },
                          on: {
                            click: function ($event) {
                              return _vm.copyToClipboard(_vm.logDialogContent)
                            },
                          },
                        },
                        [_c(VIcon, [_vm._v("mdi-content-copy")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    VBtn,
                    {
                      staticStyle: {
                        position: "absolute",
                        right: "5px",
                        top: "5px",
                      },
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.isLogDialogOpen = false
                        },
                      },
                    },
                    [_c(VIcon, [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                VCardText,
                [
                  _c(VTextarea, {
                    attrs: { rows: "15" },
                    model: {
                      value: _vm.logDialogContent,
                      callback: function ($$v) {
                        _vm.logDialogContent = $$v
                      },
                      expression: "logDialogContent",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { "max-width": "1000", "max-height": "1000" },
          model: {
            value: _vm.isQueryDialogOpen,
            callback: function ($$v) {
              _vm.isQueryDialogOpen = $$v
            },
            expression: "isQueryDialogOpen",
          },
        },
        [
          _c(
            VCard,
            [
              _c(
                "v-card-item",
                [
                  _c(
                    VCardTitle,
                    { staticStyle: { display: "flex" } },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.queryDialogTitle) +
                          "\n                    "
                      ),
                      _c(
                        VBtn,
                        {
                          staticStyle: {
                            "margin-top": "2px",
                            "margin-left": "2px",
                          },
                          attrs: { icon: "", small: "" },
                          on: {
                            click: function ($event) {
                              return _vm.copyToClipboard(_vm.queryDialogContent)
                            },
                          },
                        },
                        [_c(VIcon, [_vm._v("mdi-content-copy")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    VBtn,
                    {
                      staticStyle: {
                        position: "absolute",
                        right: "5px",
                        top: "5px",
                      },
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.isQueryDialogOpen = false
                        },
                      },
                    },
                    [_c(VIcon, [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                VCardText,
                [
                  _c(VTextarea, {
                    attrs: { rows: "15" },
                    model: {
                      value: _vm.queryDialogContent,
                      callback: function ($$v) {
                        _vm.queryDialogContent = $$v
                      },
                      expression: "queryDialogContent",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VSnackbar,
        {
          attrs: { timeout: 2000 },
          model: {
            value: _vm.showSnackbar,
            callback: function ($$v) {
              _vm.showSnackbar = $$v
            },
            expression: "showSnackbar",
          },
        },
        [_vm._v("\n        클립보드에 복사되었습니다.\n    ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }