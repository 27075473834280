import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.modelCreationCompleted
    ? _c(
        "div",
        [
          !_vm.openGeneratorUI
            ? _c(
                VBtn,
                {
                  staticClass: "generator-ui-btn",
                  attrs: { fab: "", primary: "", fixed: "" },
                  on: {
                    click: function ($event) {
                      _vm.openGeneratorUI = !_vm.openGeneratorUI
                    },
                  },
                },
                [_c(VIcon, [_vm._v("mdi-auto-fix")])],
                1
              )
            : _vm._e(),
          _vm.openGeneratorUI
            ? _c(
                VRow,
                {
                  staticStyle: {
                    position: "absolute",
                    right: "30px",
                    top: "75px",
                  },
                },
                [
                  _c(
                    VCard,
                    {
                      staticStyle: { "text-align": "center", "z-index": "2" },
                      attrs: { width: "auto" },
                    },
                    [
                      _c(
                        VCardText,
                        {
                          staticStyle: { padding: "0px" },
                          style:
                            _vm.isExpanded && _vm.generationStopped
                              ? { width: "75px" }
                              : _vm.isExpanded
                              ? { width: "170px" }
                              : { width: "500px" },
                        },
                        [
                          _vm.generationStopped
                            ? _c(VProgressLinear, {
                                staticStyle: {
                                  "margin-top": "10px",
                                  "pointer-events": "none",
                                },
                                attrs: { indeterminate: _vm.generationStopped },
                              })
                            : _vm._e(),
                          _vm.showStopBtn && _vm.generationStopped
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "text-align": "right",
                                    position: "absolute",
                                    right: "15px",
                                    top: "65px",
                                  },
                                },
                                [
                                  _c(
                                    VTooltip,
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  VBtn,
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticStyle: {
                                                          "z-index": "2",
                                                        },
                                                        attrs: {
                                                          icon: "",
                                                          small: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.stop()
                                                          },
                                                        },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      VIcon,
                                                      {
                                                        staticStyle: {
                                                          "margin-right": "5px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-stop-circle-outline"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1137710734
                                      ),
                                    },
                                    [_c("span", [_vm._v("Stop")])]
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                {
                                  staticStyle: {
                                    "text-align": "right",
                                    position: "absolute",
                                    right: "10px",
                                    top: "55px",
                                  },
                                },
                                [
                                  _vm.isShowRegenerateBtn &&
                                  !_vm.SelectChatTab &&
                                  _vm.prevUsedGeneratorTabIndex !== null
                                    ? [
                                        _c(
                                          VTooltip,
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      !_vm.hasElements &&
                                                      _vm.generatorName ===
                                                        "BMGenerator"
                                                        ? _c(
                                                            VBtn,
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "gs-es-auto-modling-btn",
                                                                  staticStyle: {
                                                                    "margin-right":
                                                                      "5px",
                                                                    "z-index":
                                                                      "2",
                                                                  },
                                                                  attrs: {
                                                                    icon: "",
                                                                    small: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.reGenerate(
                                                                          _vm
                                                                            .input[
                                                                            "userStory"
                                                                          ]
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                "v-btn",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _c(VIcon, [
                                                                _vm._v(
                                                                  "mdi-refresh"
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          )
                                                        : _c(
                                                            VBtn,
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "gs-es-auto-modling-btn",
                                                                  staticStyle: {
                                                                    "margin-right":
                                                                      "5px",
                                                                    "z-index":
                                                                      "2",
                                                                  },
                                                                  attrs: {
                                                                    icon: "",
                                                                    small: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.reGenerate(
                                                                          _vm
                                                                            .input[
                                                                            "userStory"
                                                                          ]
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                "v-btn",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _c(VIcon, [
                                                                _vm._v(
                                                                  "mdi-refresh"
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2694552564
                                            ),
                                          },
                                          [_c("span", [_vm._v("Try again")])]
                                        ),
                                      ]
                                    : _vm._e(),
                                  _vm.showContinueBtn &&
                                  (_vm.isAutoGen || _vm.generationCompleted)
                                    ? _c(
                                        VTooltip,
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _vm.generatorStep ===
                                                      "aggregate" ||
                                                    _vm.generatorName ===
                                                      "CJMGenerator" ||
                                                    _vm.generatorName ===
                                                      "BMGenerator" ||
                                                    _vm.generatorName ===
                                                      "UserStoryMapGenerator"
                                                      ? _c(
                                                          VBtn,
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "gs-es-auto-modling-btn",
                                                                staticStyle: {
                                                                  padding:
                                                                    "0px 5px",
                                                                  "margin-right":
                                                                    "10px",
                                                                },
                                                                attrs: {
                                                                  small: "",
                                                                  color:
                                                                    "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.finishModelCreation()
                                                                    },
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm.generatorName ===
                                                              "CJMGenerator" ||
                                                            _vm.generatorName ===
                                                              "BMGenerator" ||
                                                            _vm.generatorName ===
                                                              "UserStoryMapGenerator"
                                                              ? _c("div", [
                                                                  _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "Icon",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              float:
                                                                                "left",
                                                                              "margin-right":
                                                                                "3px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              icon: "ri:check-fill",
                                                                              width:
                                                                                "16",
                                                                              height:
                                                                                "16",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        "complete"
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ])
                                                              : _c("div", [
                                                                  _c(
                                                                    "span",
                                                                    [
                                                                      _vm._v(
                                                                        "CONTINUE"
                                                                      ),
                                                                      _c(
                                                                        VIcon,
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-arrow-right"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1055535864
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Auto modeling completed"),
                                          ]),
                                        ]
                                      )
                                    : _vm.showGenerateBtn
                                    ? _c(
                                        VTooltip,
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    !_vm.hasElements
                                                      ? _c(
                                                          VBtn,
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "gs-es-auto-modling-btn",
                                                                staticStyle: {
                                                                  padding:
                                                                    "0px 5px",
                                                                  "margin-right":
                                                                    "10px",
                                                                },
                                                                attrs: {
                                                                  small: "",
                                                                  color:
                                                                    "primary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.generate()
                                                                    },
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("div", [
                                                              _c(
                                                                "span",
                                                                [
                                                                  _vm._v(
                                                                    "Generate"
                                                                  ),
                                                                  _c(VIcon, [
                                                                    _vm._v(
                                                                      "mdi-arrow-right"
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1673316921
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Click to generate model"),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.showContinueBtn
                                    ? _vm._t("buttons")
                                    : _vm._e(),
                                ],
                                2
                              ),
                          _c(
                            VTabs,
                            {
                              model: {
                                value: _vm.userPanel,
                                callback: function ($$v) {
                                  _vm.userPanel = $$v
                                },
                                expression: "userPanel",
                              },
                            },
                            [
                              _vm._l(_vm.tabs, function (tab) {
                                return _c(
                                  VTab,
                                  {
                                    key: tab.component,
                                    staticStyle: { "z-index": "3" },
                                    style:
                                      _vm.isExpanded | _vm.isGenerated
                                        ? { display: "none" }
                                        : {},
                                    attrs: {
                                      disabled:
                                        _vm.hasElements &&
                                        !tab.isAlwaysActivated,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.switchGenerator(
                                          "tab",
                                          tab.isShowGenerateBtn,
                                          tab.isShowContinueBtn,
                                          tab.isShowStopBtn,
                                          tab.isShowRegenerateBtn
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(tab.name))]
                                )
                              }),
                              _c(
                                VTab,
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.canvasType ===
                                          "event-storming-model-canvas" ||
                                        _vm.canvasType ===
                                          "context-mapping-model-canvas",
                                      expression:
                                        "canvasType === 'event-storming-model-canvas' || canvasType === 'context-mapping-model-canvas'",
                                    },
                                  ],
                                  staticStyle: { "z-index": "3" },
                                  style: _vm.isExpanded
                                    ? { display: "none" }
                                    : {},
                                  on: {
                                    click: function ($event) {
                                      return _vm.switchGenerator(
                                        "DDL",
                                        false,
                                        false,
                                        false,
                                        false
                                      )
                                    },
                                  },
                                },
                                [_vm._v("DDL")]
                              ),
                              _c(
                                VTab,
                                {
                                  staticStyle: { "z-index": "3" },
                                  style:
                                    _vm.isExpanded | _vm.isGenerated
                                      ? { display: "none" }
                                      : {},
                                  on: {
                                    click: function ($event) {
                                      return _vm.switchGenerator(
                                        "input",
                                        true,
                                        true,
                                        true,
                                        true
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Input")]
                              ),
                              _c(
                                VTab,
                                {
                                  staticStyle: { "z-index": "3" },
                                  style:
                                    _vm.isExpanded | _vm.isGenerated
                                      ? { display: "none" }
                                      : {},
                                  on: {
                                    click: function ($event) {
                                      return _vm.switchGenerator(
                                        "output",
                                        false,
                                        true,
                                        true,
                                        true
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Output")]
                              ),
                              _c(
                                VTab,
                                {
                                  staticStyle: { "z-index": "3" },
                                  style: _vm.isExpanded
                                    ? { display: "none" }
                                    : {},
                                  on: {
                                    click: function ($event) {
                                      return _vm.switchGenerator(
                                        "chat",
                                        false,
                                        false,
                                        false,
                                        false
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Chat")]
                              ),
                            ],
                            2
                          ),
                          _c(
                            VExpansionPanels,
                            {
                              model: {
                                value: _vm.autoModelDialog,
                                callback: function ($$v) {
                                  _vm.autoModelDialog = $$v
                                },
                                expression: "autoModelDialog",
                              },
                            },
                            [
                              _c(
                                VExpansionPanel,
                                { staticStyle: { padding: "0px" } },
                                [
                                  _c(VExpansionPanelHeader, {
                                    staticStyle: { position: "absolute" },
                                    attrs: { "disable-icon-rotate": "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "actions",
                                          fn: function () {
                                            return [
                                              _c(
                                                VTooltip,
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on,
                                                          attrs,
                                                        }) {
                                                          return [
                                                            _c(
                                                              VBtn,
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "cp-panel-folding",
                                                                    staticStyle:
                                                                      {
                                                                        position:
                                                                          "absolute",
                                                                        top: "-38px",
                                                                        right:
                                                                          "0px",
                                                                        "z-index":
                                                                          "2",
                                                                      },
                                                                    attrs: {
                                                                      icon: "",
                                                                      small: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.openGeneratorUI =
                                                                            !_vm.openGeneratorUI
                                                                        },
                                                                    },
                                                                  },
                                                                  "v-btn",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c(VIcon, [
                                                                  _vm._v(
                                                                    "mdi-close"
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    653971313
                                                  ),
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "close Auto Generator"
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      642354105
                                    ),
                                  }),
                                  _c(
                                    VExpansionPanelContent,
                                    {
                                      staticClass: "auto-modeling-dialog pa-0",
                                    },
                                    [
                                      _c(
                                        VTabsItems,
                                        {
                                          model: {
                                            value: _vm.userPanel,
                                            callback: function ($$v) {
                                              _vm.userPanel = $$v
                                            },
                                            expression: "userPanel",
                                          },
                                        },
                                        [
                                          _vm._l(_vm.tabs, function (tab) {
                                            return _c(
                                              VTabItem,
                                              {
                                                key: tab.component,
                                                attrs: {
                                                  disabled: _vm.hasElements,
                                                },
                                              },
                                              [
                                                _c(tab.component, {
                                                  ref: tab.component,
                                                  refInFor: true,
                                                  tag: "component",
                                                  attrs: {
                                                    initValue: tab.initValue,
                                                  },
                                                  on: {
                                                    generate: function (
                                                      $event
                                                    ) {
                                                      return _vm.generate()
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          }),
                                          _c(
                                            VTabItem,
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.canvasType ===
                                                      "event-storming-model-canvas" ||
                                                    _vm.canvasType ===
                                                      "context-mapping-model-canvas",
                                                  expression:
                                                    "canvasType === 'event-storming-model-canvas' || canvasType === 'context-mapping-model-canvas'",
                                                },
                                              ],
                                            },
                                            [
                                              _c(
                                                VCard,
                                                {
                                                  staticStyle: {
                                                    padding: "10px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "max-height": "55vh",
                                                        "margin-bottom": "10px",
                                                        overflow: "auto",
                                                      },
                                                    },
                                                    [
                                                      _c(VTextarea, {
                                                        attrs: {
                                                          label: "DDL",
                                                          "auto-grow": "",
                                                          "no-resize": "",
                                                        },
                                                        model: {
                                                          value: _vm.DDL,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.DDL = $$v
                                                          },
                                                          expression: "DDL",
                                                        },
                                                      }),
                                                      _c(
                                                        VRow,
                                                        {
                                                          staticClass:
                                                            "ma-0 pa-0",
                                                          attrs: {
                                                            align: "center",
                                                            "no-gutters": "",
                                                          },
                                                        },
                                                        [
                                                          _c(VTextField, {
                                                            staticClass:
                                                              "delete-input-detail ddl-text-field",
                                                            attrs: {
                                                              label:
                                                                "Add a Bounded Context",
                                                              dense: "",
                                                              outlined: "",
                                                            },
                                                            on: {
                                                              keyup: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  !$event.type.indexOf(
                                                                    "key"
                                                                  ) &&
                                                                  _vm._k(
                                                                    $event.keyCode,
                                                                    "enter",
                                                                    13,
                                                                    $event.key,
                                                                    "Enter"
                                                                  )
                                                                )
                                                                  return null
                                                                return _vm.addBoundedContext.apply(
                                                                  null,
                                                                  arguments
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.boundedContextInput,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.boundedContextInput =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "boundedContextInput",
                                                            },
                                                          }),
                                                          _c(
                                                            VBtn,
                                                            {
                                                              staticClass:
                                                                "ml-2",
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                icon: "",
                                                                text: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.addBoundedContext,
                                                              },
                                                            },
                                                            [
                                                              _c(VIcon, [
                                                                _vm._v(
                                                                  "mdi-plus"
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        VSheet,
                                                        _vm._l(
                                                          _vm.boundedContextLists,
                                                          function (
                                                            context,
                                                            index
                                                          ) {
                                                            return _c(
                                                              VChip,
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "ma-1",
                                                                attrs: {
                                                                  close: "",
                                                                },
                                                                on: {
                                                                  "click:close":
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeBoundedContext(
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                    " +
                                                                    _vm._s(
                                                                      context
                                                                    ) +
                                                                    "\n                                                "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                      _c(VTextarea, {
                                                        staticClass: "mt-4",
                                                        attrs: {
                                                          label:
                                                            "Business Scenario",
                                                          rows: "4",
                                                          "no-resize": "",
                                                        },
                                                        model: {
                                                          value: _vm.scenario,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.scenario = $$v
                                                          },
                                                          expression:
                                                            "scenario",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  !_vm.generationStopped
                                                    ? _c(
                                                        VBtn,
                                                        {
                                                          staticClass:
                                                            "prompt_field generator-ui-text-field",
                                                          attrs: {
                                                            color: "primary",
                                                            block: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.generate()
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("Generate")]
                                                      )
                                                    : _vm._e(),
                                                  _vm.generationStopped
                                                    ? _c(
                                                        VProgressCircular,
                                                        {
                                                          attrs: {
                                                            indeterminate: "",
                                                          },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VTabItem,
                                            {
                                              attrs: {
                                                disabled: _vm.hasElements,
                                              },
                                            },
                                            [
                                              _c(
                                                VCard,
                                                { attrs: { flat: "" } },
                                                [
                                                  _vm.input
                                                    ? _c(VTextarea, {
                                                        staticClass:
                                                          "auto-modeling-dialog-textarea",
                                                        staticStyle: {
                                                          "font-size": "small",
                                                          "padding-top": "40px",
                                                          height: "100%",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.input.userStory,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.input,
                                                              "userStory",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "input.userStory",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VTabItem,
                                            {
                                              attrs: {
                                                disabled: _vm.hasElements,
                                              },
                                            },
                                            [
                                              _c(
                                                VCard,
                                                { attrs: { flat: "" } },
                                                [
                                                  _c(VTextarea, {
                                                    staticClass:
                                                      "auto-modeling-dialog-textarea",
                                                    staticStyle: {
                                                      "font-size": "small",
                                                      "padding-top": "40px",
                                                      height: "100%",
                                                    },
                                                    attrs: {
                                                      id: "scroll-text",
                                                    },
                                                    on: {
                                                      scroll: _vm.handleScroll,
                                                    },
                                                    model: {
                                                      value: _vm.displayResult,
                                                      callback: function ($$v) {
                                                        _vm.displayResult = $$v
                                                      },
                                                      expression:
                                                        "displayResult",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VTabItem,
                                            [
                                              _c(
                                                VCard,
                                                { attrs: { flat: "" } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        height: "70vh",
                                                        overflow: "auto",
                                                        padding: "10px",
                                                        "border-bottom":
                                                          "solid 2px rgba(0, 0, 0, 0.2)",
                                                      },
                                                      attrs: {
                                                        id: "scroll_messageList",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        VAlert,
                                                        {
                                                          staticStyle: {
                                                            "text-align":
                                                              "left",
                                                          },
                                                          attrs: {
                                                            dense: "",
                                                            color: "blue",
                                                            outlined: "",
                                                            type: "info",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.modifyInfo
                                                            ) +
                                                              "\n                                            "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        VCol,
                                                        {
                                                          staticClass: "pa-0",
                                                          attrs: { cols: "12" },
                                                        },
                                                        _vm._l(
                                                          _vm.chatList,
                                                          function (
                                                            message,
                                                            idx
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              { key: idx },
                                                              [
                                                                message.type ==
                                                                "prompt"
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-end",
                                                                        staticStyle:
                                                                          {
                                                                            "margin-bottom":
                                                                              "20px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          VSheet,
                                                                          {
                                                                            staticClass:
                                                                              "pa-2",
                                                                            staticStyle:
                                                                              {
                                                                                "background-color":
                                                                                  "#E5F3FB",
                                                                                "border-radius":
                                                                                  "6px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                message.text
                                                                              ) +
                                                                                "\n                                                        "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : message.type ==
                                                                    "response"
                                                                  ? _c(
                                                                      VSheet,
                                                                      {
                                                                        staticClass:
                                                                          "grey lighten-3 pa-2",
                                                                        staticStyle:
                                                                          {
                                                                            display:
                                                                              "inline-block",
                                                                            width:
                                                                              "415px",
                                                                            overflow:
                                                                              "auto",
                                                                            "text-align":
                                                                              "left",
                                                                            "margin-bottom":
                                                                              "20px",
                                                                            "border-radius":
                                                                              "6px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "pre",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                "font-size":
                                                                                  "small",
                                                                                "text-align":
                                                                                  "left",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                message.text
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(VTextField, {
                                                    staticClass:
                                                      "prompt_field generator-ui-text-field",
                                                    attrs: {
                                                      solo: "",
                                                      autofocus: "",
                                                      "append-icon": "mdi-send",
                                                      disabled:
                                                        _vm.selectedElement
                                                          .length === 0 &&
                                                        _vm.canvasType !==
                                                          "uml-class-model-canvas",
                                                    },
                                                    on: {
                                                      "click:append": function (
                                                        $event
                                                      ) {
                                                        return _vm.generate()
                                                      },
                                                      keypress: function (
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        )
                                                          return null
                                                        return _vm.generate()
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.chatMessage,
                                                      callback: function ($$v) {
                                                        _vm.chatMessage = $$v
                                                      },
                                                      expression: "chatMessage",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }